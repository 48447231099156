import React from 'react';
import Breadcrumb from '../common/breadcrumb';
import Footer from '../footer/footer';
import HeaderFour from '../header/headerFour';
import CartSection from './cartSection';
import { useTranslation } from "react-i18next";

const CartComponent = () => {
    
    const { t } = useTranslation();

    return (
        <>
            <HeaderFour />
            <main>
                <Breadcrumb breadcrumbTitle={t('MyCart')} breadcrumbSubTitle={t('Cart')} />
                <CartSection />
            </main>
            <Footer />
        </>
    );
};

export default CartComponent;