import * as React from "react"
import Layout from "../../components/layout"
import CartMain from "../../components/cart"
import {graphql} from 'gatsby';

const CartPage = () => (
  <Layout>
    <CartMain />
  </Layout>
)

export default CartPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;