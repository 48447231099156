import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddDiscountAction, setRemoveProductAction } from '../../redux/cart/cartSlice';
import { formatCurrency } from '../../utils';
import { useTranslation } from "react-i18next";
const CartSection = () => {
    const cart = useSelector(state=> state.cart);
   const dispatch = useDispatch();

   const { t } = useTranslation();
   
   React.useEffect(()=>{

    if(cart && cart.products.length > 0){

        const cartItems = cart.products.map((data)=>{
            return{
                item_id: data.id,
                item_name: data.name,
                price: parseFloat(data.price),
                quantity: 1
            }
        })
    
        if (typeof window !== 'undefined') {
            
            //Add event add_to_cart
            window.dataLayer.push({
                event: "view_cart",
                currency: "USD",
                value: parseFloat(cart.total),
                items: cartItems
            });
        }
    }

   },[cart])

   const handleRemoveFromCart = (id) => {

    dispatch(setRemoveProductAction(id))

    if (typeof window !== 'undefined') {
        
        //Send event to GTM
        window.dataLayer.push({
          event: "remove_from_cart",
          currency: "USD",
          value: parseFloat(cart.total),
          items: cart.products.map((data)=>{
            return {
                item_id: data.id,
                item_name: data.name,
                price: parseFloat(data.price),
                quantity: 1
            }
        })
        });
        
      }
      
   }
   
    return (
        <section className="cart-area pt-100 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 d-flex align-items-center">
                        <div className="row">
                    {cart &&
                        cart.products.map((data) => (
                        <div key={data.id} style={{borderBottom: '1px solid #edeef2'}}>
                        <div className="col-lg-12" >
                            <div className="card border-0" >
                            <div className="row no-gutters">
                                <div className="col-md-3">
                                <Link to={"/course-details/" + data.slug}>
                                    <img
                                    src={data.image}
                                    alt="imagen del curso"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain'
                                    }}
                                    />
                                </Link>
                                </div>
                                <div className="col-md-9">
                                <div className="card-body">
                                    <h5 className="card-title">
                                    <Link to={"/course-details/" + data.slug}>
                                        {data.name}
                                    </Link>
                                    </h5>
                                    <p className="card-text">
                                    USD {formatCurrency(data.price)}
                                    </p>
                                    <button
                                    type="button"
                                    onClick={() =>handleRemoveFromCart(data.id)}
                                    className="btn btn-outline-danger border-0"
                                    >
                                    X
                                    </button>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div  ></div>
                        </div>
                        ))}
                        </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                                <div className="cart-page-total">
                                    <h2></h2>
                                    <ul>
                                        <li>Subtotal <span>USD ${formatCurrency(cart.subtotal)}</span></li>
                                        <li>{t('Discount')} <span>USD ${formatCurrency(cart.discount)}</span></li>
                                        <li>Total <span>USD ${formatCurrency(cart.total)}</span></li>
                                    </ul>
                                    <Link to="/checkout" className="edu-border-btn mt-20">{t('GoToPay')}</Link>
                                </div>
                            </div>
                </div>
            </div>
        </section>
    );
};

export default CartSection;